window.addEventListener("scroll", (event) => {
  paralax();
});
window.addEventListener("load", (event) => {
  paralax();
});

function paralax() {
  var item = document.getElementsByClassName("paraX");
  for (var i = 0; i < item.length; i++) {
    if (!item.item(i).classList.contains("paraXruned")) {
      //console.log(item.item(i).dataset.paralax);

      var y = window.pageYOffset - item.item(i).offsetTop + window.innerHeight;
      var breakY = 100;
      if (window.innerHeight < 650) breakY = 50;
      if (y > breakY) {
        item.item(i).classList.add("paraXruned");
        item.item(i).classList.add(item.item(i).dataset.paralax);
      }
    }
  }
}
