"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./images/*.{png,jpg,jpeg,gif,svg}";

// UIkit
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

// loads the Icon plugin
UIkit.use(Icons);

// import stylu
import "./index.scss";

// menu
import "./scripts/menu";

// paralax
import "./scripts/paralax";
//console.log("I am ready!");
