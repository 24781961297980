//MENU OPEN
document.getElementById("ccollapseToggle").onclick = function () {
  menuManipulate();
};

var submenuCloserButton = document.getElementsByClassName("submenuS");

for (var i = 0; i < submenuCloserButton.length; i++) {
  submenuCloserButton[i].addEventListener("click", menuClose, false);
}

const logoTextI = document.getElementById("logoTextImg");
logoTextI.addEventListener("load", removeEl);

function menuManipulate() {
  const openQ = document.querySelector("#ccollapse");
  const openFake = document.querySelector("#ccollapseFake");
  if (openQ.classList.contains("open")) {
    //console.log("otevřeno");
    openQ.classList.remove("open");
    openFake.classList.remove("open");
  } else {
    //console.log("zavřeno");
    openQ.classList.add("open");
    openFake.classList.add("open");
  }
}

function menuClose() {
  const openQ = document.querySelector("#ccollapse");
  openQ.classList.remove("open");
}

function removeEl() {
  //console.log("volám Remove");
  var element = document.getElementById("logoText");
  element.parentNode.removeChild(element);
}
